import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import { fetchTopMatches, fetchClearFavorites } from 'stores/match/index.js';
import { AWS_BUCKET_URL } from 'toolkit/constants.js';
// components
import BlackBox from 'components/general/black_box.js';
import MatchCard from 'components/general/match_card.js';
import Loading from 'components/general/loading.js';

function HomeMobile() {
  const { t } = useTranslation('translations', { useSuspense: false });
  const clearFavorites = useSelector((state) => state.match.clearFavorites);
  const topMatches = useSelector((state) => state.match.topMatches);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loadingClearFavorites, setLoadingClearFavorites] = useState(false);
  const [loadingTopMatches, setLoadingTopMatches] = useState(false);
  const france = `${AWS_BUCKET_URL}/ClientFlags/1953.svg`;
  const spain = `${AWS_BUCKET_URL}/ClientFlags/1959.svg`;
  const bundesliga = `${AWS_BUCKET_URL}/ClientFlags/1954.svg`;
  const premier = `${AWS_BUCKET_URL}/ClientFlags/1952.svg`;
  const turkey = `${AWS_BUCKET_URL}/ClientFlags/46.svg`;
  const italy = `${AWS_BUCKET_URL}/ClientFlags/1955.svg`;
  const portugal = `${AWS_BUCKET_URL}/ClientFlags/1919.svg`;
  const russia = `${AWS_BUCKET_URL}/ClientFlags/2257.svg`;
  const holland = `${AWS_BUCKET_URL}/ClientFlags/1956.svg`;
  const belgium = `${AWS_BUCKET_URL}/ClientFlags/1950.svg`;
  // const euro = `${AWS_BUCKET_URL}/Leagues/euro.png`;
  // const america = `${AWS_BUCKET_URL}/Leagues/copaamerica.png`;
  // const libertadores = `${AWS_BUCKET_URL}/Leagues/libertadores.png`;
  // const sudamericana = `${AWS_BUCKET_URL}/Leagues/sudamericana.png`;
  // const argentina = `${AWS_BUCKET_URL}/ClientFlags/48.svg`;
  // const brazil = `${AWS_BUCKET_URL}/ClientFlags/13.svg`;
  // const americaleague = `${AWS_BUCKET_URL}/ClientFlags/26.svg`;
  // const japan = `${AWS_BUCKET_URL}/ClientFlags/52.svg`;

  const swiperOptions = {
    // cssMode: true,
    slidesPerView: 3.5,
    className: 'mb-4',
    loop: true,
    // freeMode: true,
    spaceBetween: 10,
    pagination: {
      clickable: true
    },
    mousewheel: true,
    // autoHeight: true,
    modules: [Mousewheel]
  };

  const topCategories = [
    {
      to: user === null ? '/login' : '/casino',
      icon: `${AWS_BUCKET_URL}/Icons/liveicon.svg`,
      text1: t('live'),
      text2: t('casino')
    },
    {
      to: user === null ? '/login' : '/slot',
      sportTypeVisibleParam: false,
      icon: `${AWS_BUCKET_URL}/Icons/sloticon.svg`,
      text2: t('slot')
    },
    {
      to: '/sport-details?time=Today&show=true',
      icon: `${AWS_BUCKET_URL}/Icons/todayicon.svg`,
      text2: t('today')
    },
    {
      to: '/clear-favorites',
      icon: `${AWS_BUCKET_URL}/Icons/clearfavouritesicon.svg`,
      text1: t('clear'),
      text2: t('favourites')
    },
    {
      to: '/results',
      icon: `${AWS_BUCKET_URL}/Icons/resultsicon.svg`,
      text2: t('results')
    }
    // {
    //   to: '/automatic-bets',
    //   icon: `${AWS_BUCKET_URL}/Icons/automaticbetsicon.svg`,
    //   text1: t('automatic'),
    //   text2: t('pages.sport.bets')
    // }
  ];

  const getData = () => {
    try {
      setLoadingClearFavorites(true);
      setLoadingTopMatches(true);
      dispatch(fetchTopMatches({ sportType: '0' }))
        .unwrap()
        .then(() => {
          setLoadingTopMatches(false);
        });
      dispatch(fetchClearFavorites({ size: 5, sportType: '0' }))
        .unwrap()
        .then(() => {
          setLoadingClearFavorites(false);
        });
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container fluid className="g-1">
      {/* top matches start */}
      {loadingTopMatches ? (
        <Loading message={t('pages.start.messageLoadingTopMatches')} />
      ) : (
        <section className="my-3">
          <div className="d-flex justify-content-between">
            <h6>{t('pages.start.titleTopMatches')}</h6>
            <Link className="link-danger fs-7" to="/top-matches">
              {t('pages.start.titleTopMatches')}
            </Link>
          </div>
          <Swiper {...{ ...swiperOptions, slidesPerView: 1.1, className: '' }}>
            {topMatches.map((topMatch) => {
              return (
                <SwiperSlide key={topMatch}>
                  <MatchCard
                    providerId={topMatch}
                    marketType={1}
                    locales={locales}
                    selectedLocale={selectedLocale}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      )}
      {/* top matches end */}

      {/* top categories start */}
      <section className="mb-3">
        <h6>{t('pages.start.titleTopCategories')}</h6>
        <Swiper {...swiperOptions}>
          {topCategories.map((tc, i) => (
            <SwiperSlide key={i}>
              <BlackBox {...tc} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      {/* top categories end */}

      {/* top leagues start */}
      <section className="mb-3">
        <h6>{t('pages.start.titleTopLeagues')}</h6>
        {/* <Swiper {...swiperOptions}> */}
        {/* <SwiperSlide>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:4&league=sr:tournament:1"
            icon={euro}
            text2={'Euro 2024'}
            iconType="flag"
          />
          <div className="my-3"></div>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:4&league=sr:tournament:133"
            icon={america}
            text2={'Copa America'}
            iconType="flag"
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:393&league=sr:tournament:384"
            icon={libertadores}
            text2={'Libertadores'}
            iconType="flag"
          />
          <div className="my-3"></div>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:393&league=sr:tournament:480"
            icon={sudamericana}
            text2={'Sudamericana'}
            iconType="flag"
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:48&league=sr:tournament:155"
            icon={argentina}
            text2={t('categories.Argentina')}
            iconType="flag"
          />
          <div className="my-3"></div>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:13&league=sr:tournament:325"
            icon={brazil}
            text2={t('categories.Brazil')}
            iconType="flag"
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:26&league=sr:tournament:28163"
            icon={americaleague}
            text2={t('categories.USA')}
            iconType="flag"
          />
          <div className="my-3"></div>
          <BlackBox
            to="/sport-details/Football/matches?category=sr:category:52&league=sr:tournament:196"
            icon={japan}
            text2={t('categories.Japan')}
            iconType="flag"
          />
        </SwiperSlide> */}
        {/* <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:32&league=sr:tournament:8"
              icon={brazil}
              text2={t('categories.Brazil')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:7&league=sr:tournament:34"
              icon={france}
              text2={t('categories.France')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:35&league=sr:tournament:37"
              icon={holland}
              text2={t('categories.Netherlands')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:33&league=sr:tournament:38"
              icon={belgium}
              text2={t('categories.Belgium')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:44&league=sr:tournament:238"
              icon={portugal}
              text2={t('categories.Portugal')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:21&league=sr:tournament:203"
              icon={russia}
              text2={t('categories.Russia')}
              iconType="flag"
            />
          </SwiperSlide> */}
        {/* </Swiper> */}
        <Swiper {...swiperOptions}>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:30&league=sr:tournament:35"
              icon={bundesliga}
              text2={t('categories.Bundesliga')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:30&league=sr:tournament:44"
              icon={bundesliga}
              text2={t('categories.Bundesliga2')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:1&league=sr:tournament:17"
              icon={premier}
              text1={t('categories.Premier')}
              text2={t('league')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:1&league=sr:tournament:18"
              icon={premier}
              text2={t('categories.Championship')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:46&league=sr:tournament:52"
              icon={turkey}
              text2={t('categories.Turkey')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:31&league=sr:tournament:23"
              icon={italy}
              text2={t('categories.Italy')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:32&league=sr:tournament:8"
              icon={spain}
              text2={t('categories.Spain')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:7&league=sr:tournament:34"
              icon={france}
              text2={t('categories.France')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:35&league=sr:tournament:37"
              icon={holland}
              text2={t('categories.Netherlands')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:33&league=sr:tournament:38"
              icon={belgium}
              text2={t('categories.Belgium')}
              iconType="flag"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:44&league=sr:tournament:238"
              icon={portugal}
              text2={t('categories.Portugal')}
              iconType="flag"
            />
            <div className="my-3"></div>
            <BlackBox
              to="/sport-details/Football/matches?category=sr:category:21&league=sr:tournament:203"
              icon={russia}
              text2={t('categories.Russia')}
              iconType="flag"
            />
          </SwiperSlide>
        </Swiper>
      </section>
      {/* top leagues end */}

      {loadingClearFavorites ? (
        <Loading message={t('pages.start.messageLoadingClearFavorites')} />
      ) : (
        <section className="mb-3">
          <div className="d-flex justify-content-between">
            <h6>{t('pages.start.titleClearFavorites')}</h6>
            <Link className="link-danger fs-7" to="/clear-favorites">
              {t('pages.start.linkClearFavorites')}
            </Link>
          </div>
          <Swiper {...swiperOptions} slidesPerView={1.1}>
            {clearFavorites.slice(0, 5).map((clearFavoritesMatchId) => (
              <SwiperSlide key={clearFavoritesMatchId}>
                <MatchCard
                  providerId={clearFavoritesMatchId}
                  marketType={1}
                  locales={locales}
                  selectedLocale={selectedLocale}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )}
    </Container>
  );
}
export default HomeMobile;
